import { PrimaryCta } from '@rsa-digital/evo-shared-components/components/Cta';
import { FullWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const Heading = styled.h1`
  ${fonts.headingLarge};
  margin: ${spacing(4)} 0 ${spacing(1)};

  ${mediaQuery.tabletLandscape`
    margin: ${spacing(8)} 0 ${spacing(2)};
  `}
`;

export const ImageWrapper = styled.div`
  margin-top: ${spacing(8)};

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(4)};
  `}
`;

export const StyledPrimaryCta = styled(PrimaryCta)`
  margin-top: ${spacing(4)};
  width: 100%;

  ${mediaQuery.tabletPortrait`
    width: auto;
  `}
`;

export const StyledFullWidthDivider = styled(FullWidthDivider)`
  margin-top: ${spacing(8)};
`;
