import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import { formatLongDateWithDayFirst } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import accountClient from 'api/accountClient';
import { graphql, navigate } from 'gatsby';
import React, { useCallback, useEffect, useReducer } from 'react';
import ConfirmationPage, { ConfirmationData } from 'templates/ConfirmationPage';
import Layout from 'components/Layout';
import { getHashParam } from 'helpers/getQueryParam';
import { navigatePreservingQuote } from 'helpers/navigation';
import replaceCsPlaceholders from 'helpers/replaceCsPlaceholders';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import useApiRequestHandlerWithLoadingState from 'helpers/useApiRequestHandler';
import useAzureErrorHandler from 'helpers/useAzureErrorHandler';
import { usePageTracking } from 'helpers/usePageTracking';
import { ErrorType, UPDATE_ERROR } from 'state/error/actions';
import { useQuote } from 'state/quote/quote';
import useDispatch from 'state/useDispatch';
import { useUser } from 'state/user/state';

const STEP = 8;

export type ConfirmationProps = {
  data: {
    allCsConfirmation: { nodes: ConfirmationData[] };
  };
  location: Location & {
    state?: { policyNumber?: string; startDate?: string };
  };
};

export const query = graphql`
  query {
    allCsConfirmation {
      nodes {
        page_id
        meta {
          meta_title
        }
        heading
        subheading
        image {
          ...CsAsset
        }
        cta_text
        body
      }
    }
  }
`;

const getPageId = (isLoggedIn: boolean | undefined): string => {
  if (isLoggedIn) {
    return 'account-logged-in';
  }
  return 'account-logged-out';
};

const Confirmation: React.FC<ConfirmationProps> = ({ data, location }) => {
  const [user, setUser] = useUser();
  const [loginAttempted, setLoginAttempted] = useReducer(() => true, false);
  const quote = useQuote();
  const dispatch = useDispatch();
  const { requestHandler, isLoading } = useApiRequestHandlerWithLoadingState();
  const handleAzureError = useAzureErrorHandler();

  const policyNumber = location.state?.policyNumber;
  const startDate = location.state?.startDate;

  const idToken = getHashParam(location, 'id_token');
  const error = getHashParam(location, 'error');

  const loginAndRetrieveData = useCallback(
    (token: string) => {
      requestHandler(() => {
        setLoginAttempted();

        return accountClient.login(token).then(() => {
          setUser({ isLoggedIn: true });

          const policyNum = window.sessionStorage.getItem('policyNumber');
          const policyStartDate = window.sessionStorage.getItem('policyStartDate');

          window.sessionStorage.removeItem('policyNumber');
          window.sessionStorage.removeItem('policyStartDate');

          navigate(quoteAndBuyRoutes.confirmation(), {
            state: {
              policyNumber: policyNum,
              startDate: policyStartDate,
            },
            replace: true,
          });
        });
      });
    },
    [requestHandler, setUser]
  );

  useEffect(() => {
    if (!user) {
      return;
    }
    if (error) {
      handleAzureError(location);
      return;
    }
    if (idToken && loginAttempted) {
      return;
    }
    if (idToken) {
      loginAndRetrieveData(idToken);
      return;
    }
    if (!policyNumber || !startDate)
      if (!quote) {
        dispatch({
          type: UPDATE_ERROR,
          errorType: ErrorType.NOT_FOUND,
        });
      } else {
        navigatePreservingQuote(quoteAndBuyRoutes.quoteSummary);
      }
  }, [
    dispatch,
    loginAndRetrieveData,
    idToken,
    loginAttempted,
    policyNumber,
    quote,
    startDate,
    user,
    error,
    handleAzureError,
    location,
  ]);

  const pageId = getPageId(user?.isLoggedIn);
  const pageData = data.allCsConfirmation.nodes.find((node) => node.page_id === pageId);
  if (!pageData) {
    throw new Error(`Confirmation page with page_id '${pageId}' not found`);
  }

  usePageTracking(pageData.meta.meta_title, !!policyNumber && !!startDate && !error);

  if (!policyNumber || !startDate || !user) {
    return (
      <Layout meta={pageData.meta} quoteAndBuyStep={STEP} pageType="quoteAndBuy">
        {isLoading && <LoadingOverlay loadingMessage="Logging you in" />}
      </Layout>
    );
  }

  const processedData = replaceCsPlaceholders(
    pageData,
    { policyNumber, startDate },
    {
      policyNumber: (policyData) => policyData.policyNumber,
      startDate: (policyData) => formatLongDateWithDayFirst(policyData.startDate),
    }
  );

  return (
    <Layout meta={pageData.meta} quoteAndBuyStep={STEP} pageType="quoteAndBuy">
      <ConfirmationPage
        heading={processedData.heading}
        subheading={processedData.subheading}
        pageId={processedData.page_id}
        ctaText={processedData.cta_text}
        image={processedData.image}
      />
    </Layout>
  );
};

export default Confirmation;
