import {
  Grid,
  GridItem,
  GridItemProps,
} from '@rsa-digital/evo-shared-components/components/Grid';
import Image from '@rsa-digital/evo-shared-components/components/Image';
import fluidGridColumns from '@rsa-digital/evo-shared-components/theme/fluidGridColumns';
import { navigate } from 'gatsby';
import React, { PropsWithChildren } from 'react';
import RichTextWithModal from 'components/RichTextWithModal';
import { processImageAsset } from 'helpers/csTypeProcessors';
import { trackButtonClick } from 'helpers/eventTracking';
import { accountRoutes } from 'helpers/routingHelper';
import { useUser } from 'state/user/state';
import { CsAsset, CsMeta } from 'types/contentStack';
import {
  Heading,
  ImageWrapper,
  StyledFullWidthDivider,
  StyledPrimaryCta,
} from './styles';

export type ConfirmationData = {
  meta: CsMeta;
  heading: string;
  subheading: string;
  image: CsAsset | null;
  cta_text: string;
  page_id: string;
  body?: string;
};

export type ConfirmationPageProps = {
  heading: string;
  subheading: string;
  image: CsAsset | null;
  ctaText: string;
  pageId: string;
};

const ConfirmationPage: React.FC<PropsWithChildren<ConfirmationPageProps>> = ({
  heading,
  subheading,
  image,
  ctaText,
  pageId,
  children,
}) => {
  const [user] = useUser();

  const onCtaClick = (): void => {
    trackButtonClick('confirmationPageCta', ctaText);
    if (user?.isLoggedIn) {
      navigate(accountRoutes.account);
    } else {
      navigate(accountRoutes.login);
    }
  };

  const processedImage = processImageAsset(image);

  const textGridItemProps: GridItemProps = {
    desktop: image ? 8 : 10,
    tabletLandscape: image ? 8 : 10,
    tabletPortrait: fluidGridColumns.tabletPortrait,
    mobile: fluidGridColumns.mobile,
  };

  const imageGridItemProps: GridItemProps = {
    desktop: 4,
    tabletLandscape: 4,
    tabletPortrait: 4,
    mobile: fluidGridColumns.mobile,
  };

  return (
    <>
      <Grid data-cy={`Confirmation-${pageId}`} alignLeft>
        <GridItem {...textGridItemProps}>
          <Heading>{heading}</Heading>
          <RichTextWithModal html={subheading} />
          <StyledPrimaryCta cta={{ onClick: onCtaClick, displayText: ctaText }} />
        </GridItem>
        {processedImage && (
          <GridItem {...imageGridItemProps}>
            <ImageWrapper>
              <Image image={processedImage} />
            </ImageWrapper>
          </GridItem>
        )}
      </Grid>
      {children}
      <StyledFullWidthDivider />
    </>
  );
};

export default ConfirmationPage;
