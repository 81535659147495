import { navigate } from 'gatsby';
import { Dispatch, useCallback } from 'react';
import { ErrorType, UPDATE_ERROR, UpdateErrorAction } from 'state/error/actions';
import useDispatch from 'state/useDispatch';
import { getHashParam } from './getQueryParam';

const AZURE_ERROR_REGEX = /^(AADB2C\d+)(.*)/;

const useAzureErrorHandler = (): ((location: Location) => void) => {
  const dispatchError: Dispatch<UpdateErrorAction> = useDispatch();

  const handleAzureError = useCallback(
    (location: Location): void => {
      const error = getHashParam(location, 'error_description');
      const [, errorCode, errorDescription] = error?.match(AZURE_ERROR_REGEX) || [];

      console.error(
        `${errorCode || ''} Azure Error`,
        error ? `with description ${errorDescription || error}` : ''
      );

      dispatchError({
        type: UPDATE_ERROR,
        errorType: ErrorType.AZURE_ERROR,
      });

      navigate(location.pathname, { replace: true });
    },
    [dispatchError]
  );

  return handleAzureError;
};

export default useAzureErrorHandler;
